



import MainBanner from '../sections/banner/template_01';







import SpeakerAnnouncement from '../sections/speaker_announcement/template_01';






import SECTION_SECTION_HOW_TO_REGISTER from
'../dynamic_section/dynamic_2/template_01';





import SECTION_SECTION_HOWTOREGISTER_M from
'../dynamic_section/dynamic_2/template_01';






import Games from '../sections/games/template_01';






import SECTION_SECTION_LIVE_CASINO from
'../dynamic_section/dynamic_1/template_02';





import SECTION_SECTION_HOT_GAMES from
'../dynamic_section/dynamic_1/template_02';





import SECTION_SECTION_PARTNERS from
'../dynamic_section/dynamic_1/template_02';






import RankingList from '../sections/ranking/template_02';







import Feedback from '../sections/feedback/template_01';




import "./style.css";

import FloatingIcon from '../components/FloatingIcon';
import { useEffect, useState } from "react";
import { useMiddletier } from "../common/middletier";
import { useTranslation } from 'react-i18next';


const Main = ()=>{
  
  const [floatingIcon, setFloatingIcon] = useState([])
  const { queries, mutation } = useMiddletier()
  const { t, i18n } = useTranslation();
  useEffect(() => {
    queries([
      {
        index: 'getFloatingIcons',
        method: 'getFloatingIcons',
        params: [
          { code: 'language', graphqlType: 'String', required: true, value: i18n.resolvedLanguage },
          { code: 'website', graphqlType: 'String', required: false, value: 'MEMBER' },
        ],
        attributes: []
      }
    ])
      .then(({ data }) => {
        setFloatingIcon(data['getFloatingIcons'])
      })
      .catch((error) => {
        console.error(error)
      })
  }, [queries, i18n.resolvedLanguage])
  
return (
  <div id="main" class="page ">
  
  

  
  <MainBanner navigation={ false } speed={
    2.5 } pagination={ true } />
  
  
  
  

  
  <SpeakerAnnouncement desktopToggle={ true } mobileToggle={
    true } iconToggle={ true } titleToggle={
    false } />
  
  
  
  

  
  < SECTION_SECTION_HOW_TO_REGISTER desktopToggle={ true }
    mobileToggle={ false } label={ `Section_how_to_register` } id={ `Section_howtoregister` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ true } numberOfImage={ 1 } titlePosition={ `start` }/>
  
  
  
  

  
  < SECTION_SECTION_HOWTOREGISTER_M desktopToggle={ false }
    mobileToggle={ true } label={ `Section_howtoregister` } id={ `Section_howtoregister_m` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ true } numberOfImage={ 1 } titlePosition={ `start` }/>
  
  
  
  

  
  <Games desktopToggle={ false } mobileToggle={
    true } imageToggle={ true } titleToggle={
    false } gameProviderNameToggle={ false } rowMobile={
    2 } rowDesktop={ 4 } position={``} imageSize={
    `1x1` } folderImage={``} menuPosition={`vertical`} sliderToggle={ false } sliderRow={ 1 } pagination={ true }
    autoplay={ true } navigation={ true }/>
  
  
  
  

  
  < SECTION_SECTION_LIVE_CASINO desktopToggle={ true }
    mobileToggle={ false } label={ `Section_LIVE_CASINO` } id={ `Section_LIVE_CASINO` }
    imageSize={`1x2`} contentPosition={`bottom`}
    actionPosition={`hover`} rowDesktop={ 4 }
    rowMobile={ 3 } numberOfRow={ 1 }
    dataType={`game provider`} noPadding={ true } filter={`LC`}
    titleToggle={ true } titleIconToggle={ false }
    cardPosition={``} pagination={ false }
    autoplay={ true } navigation={ false }
    dynamicQuery={`` } filterTabToggle={ false }
    folderImage={``} noLink={ false }/>
  
  
  
  

  
  < SECTION_SECTION_HOT_GAMES desktopToggle={ true }
    mobileToggle={ true } label={ `Section_HOT_GAMES` } id={ `Section_HOT_GAMES` }
    imageSize={`1x2`} contentPosition={`bottom`}
    actionPosition={`hover`} rowDesktop={ 5 }
    rowMobile={ 2 } numberOfRow={ 1 }
    dataType={`game`} noPadding={ true } filter={`SL`}
    titleToggle={ true } titleIconToggle={ false }
    cardPosition={``} pagination={ false }
    autoplay={ true } navigation={ false }
    dynamicQuery={`HOT` } filterTabToggle={ false }
    folderImage={``} noLink={ false }/>
  
  
  
  

  
  < SECTION_SECTION_PARTNERS desktopToggle={ true }
    mobileToggle={ true } label={ `Section_partners` } id={ `Section_partners` }
    imageSize={`1x2`} contentPosition={`none`}
    actionPosition={`none`} rowDesktop={ 8 }
    rowMobile={ 3 } numberOfRow={ 1 }
    dataType={`game provider`} noPadding={ true } filter={``}
    titleToggle={ true } titleIconToggle={ false }
    cardPosition={``} pagination={ false }
    autoplay={ true } navigation={ false }
    dynamicQuery={`` } filterTabToggle={ false }
    folderImage={`logo`} noLink={ true }/>
  
  
  
  

  
  <RankingList desktopToggle={ false } mobileToggle={
    false } desktopBannerToggle={ false } mobileBannerToggle={
    false } badgeToggle={ true } userAvatarToggle={
    false } row={ 5 } titleIconToggle={ true }/>
  
  
  
  

  
  < Feedback desktopToggle={ true }
    mobileToggle={ true } contentPosition={`center`} titlePosition={`center`}rowDesktop={
    4 } rowMobile={ 2 } numberOfRow={
    1 } noPadding={ false }
    titleToggle={ true } titleIconToggle={
    false } pagination={
    false } autoplay={ true } navigation={
    false }  bgImgToggle={
      true }/>
  
  
  

  
  {floatingIcon.length > 0 && (floatingIcon?.map(function (item, index) {
    return (
      <FloatingIcon position={item?._floating_icon?.position} enableClose={item?._floating_icon?.enable_close} enableMove={item?._floating_icon?.enable_move} desktopImageUrl={item?.desktop_image_url} mobileImageUrl={item?.mobile_image_url} url={item?._floating_icon?.url} />)
  }))}

</div>
)
}

export default Main;